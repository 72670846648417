import React, { useContext, useEffect, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";

//import NotificationsIcon from "@mui/icons-material/Notifications";
import {
  Autocomplete,
  InputAdornment,
  TextField,
  createFilterOptions,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../AuthContext";
import { getAllDealers } from "../services/dealerServices";
import EmailNotificationBtn from "./EmailNotificationBtn";
import UserProfileMenu from "./UserProfileMenu";

const TopBar = (props) => {
  const {
    user,
    signOut,
    group,
    dealer,
    setDealer,
    setIsOnboarded,
    setOnboarding,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const [accountType, setAccountType] = useState("");
  const [dealerList, setDealerList] = useState([]);
  const [selectedDealer, setSelectedDealer] = useState(null);

  const filterOptions = createFilterOptions({
    stringify: (option) => option.id + option.dealerName,
  });
  useEffect(() => {
    setAccountType(group !== "jbw-dealer" ? "Admin Account" : "Dealer Account");
  }, [group]);

  useEffect(() => {
    setSelectedDealer(dealer);
  }, [dealer]);

  useEffect(() => {
    getAllDealers()
      .then(
        (res) => {
          const data = res.data;
          console.log("Global Search All Dealers list ", res.data);
          if (data && data.body.length > 0) {
            setDealerList(data.body);
          }
        },
        (err) => {
          console.log(err);
          // setClientErr("Uable to Load Clients");
        }
      )
      .catch((err) => {
        console.log(err);
        //setClientErr("Internal server error");
      });
  }, []);

  const handleLogOut = () => {
    signOut();
    navigate("/");
  };

  const barwidth = props.isOpen
    ? `calc(100% - ${"200px"})`
    : `calc(100% - ${"60px"})`;
  return (
    <AppBar
      position="fixed"
      sx={{
        width: barwidth,
        ml: "300px",
        pl: "30px",
        boxShadow: "0",
        backgroundColor: "#EFEFEF",
        color: "#000",
      }}
    >
      <Toolbar>
        <Box>
          <Typography component="h6">
            {group !== "jbw-dealer"
              ? `Hello ${user.name}.`
              : `Hello ${dealer?.dealerName}`}
          </Typography>
        </Box>
        <Box sx={{ flexGrow: 1 }} />
        {group === "jbw-admin" && (
          <Autocomplete
            freeSolo
            id="dealer-search-auto"
            disableClearable
            value={selectedDealer}
            clearOnBlur={true}
            getOptionLabel={(option) => `${option.dealerId}-${option.dealerName}`}
            filterOptions={filterOptions}
            options={dealerList}
            onChange={(event, value) => {
              console.log(value);
              //(value);
              setDealer(value);
              localStorage.setItem("dealer", JSON.stringify(value));
              if (value.onboard_status === 1) {
                navigate(`/home/dealerdetails`);
              } else {
                setOnboarding({
                  dealerId: value.dealerId,
                  dealerName: value.dealerName,
                });

                //setNavigationLink("/home/onboarding/clientlocation");
                setIsOnboarded(false);
                navigate("/home/onboarding/clientlocation", {
                  state: { key: "onboardinghome" },
                });
              }
            }}
            sx={{
              width: 450,
              borderRadius: "20px",
              mr: 20,

              ".MuiOutlinedInput-root": {
                borderRadius: "20px",
                p: "2px",
                "&:hover": {
                  borderRadius: 10,
                  borderColor: "red",
                  borderWidth: 10,
                },
              },
              "input[type='search']::-webkit-search-cancel-button": {
                display: "none",
              },
            }}
            renderInput={(params) => (
              <TextField
                sx={{
                  ".MuiInputBase-root": {
                    borderRadius: "20px",
                    borderColor: "#e0e0e0",
                    ":hover": {
                      borderColor: "#e0e0e0",
                    },
                  },
                  "& .MuiOutlinedInput-root": {
                    legend: {
                      marginLeft: "30px",
                    },
                  },
                  "& .MuiInputLabel-outlined": {
                    paddingLeft: "35px",
                  },
                }}
                {...params}
                label=""
                placeholder="Search Dealer"
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ fill: "#ffc400" }} />
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        )}

        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          <EmailNotificationBtn />
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            sx={{ color: "#a8a8a8d4", marginRight: "15px" }}
          >
            <Badge badgeContent={4} color="error">
              <MailIcon fontSize="small" />
            </Badge>
          </IconButton> */}
          {/* <IconButton
            size="large"
            aria-label="show 1 new notifications"
            sx={{ color: "#a8a8a8d4", marginLeft: "10px", marginRight: "10px" }}
          >
            <Badge badgeContent={3} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {/* <Fab
            size="small"
            edge="end"
            aria-label="account of current user"
            aria-haspopup="true"
            color="secondary"
          >
            <AccountCircle style={{ fill: "white" }} fontSize="small" />
          </Fab> */}

          <Typography
            variant="subtitle2"
            component="div"
            sx={{ marginLeft: "10px", marginRight: "10px" }}
          >
            {user.name} <br />
            <Typography
              variant="body2"
              component="div"
              sx={{ color: "#a8a8a8d4" }}
            >
              {accountType}
            </Typography>
          </Typography>
          <UserProfileMenu logOut={handleLogOut} />
          {/* <Fab
            size="small"
            edge="end"
            aria-label="Log Out current user"
            aria-haspopup="true"
            color="secondary"
            onClick={handleLogOut}
          >
            <LogoutIcon fontSize="small" />
          </Fab> */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
