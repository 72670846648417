import React, { useContext, useEffect, useState } from "react";
//import { AuthContext } from "../AuthContext";
import {
  getAgreementEquipmentsList,
  submitAgreementEquipments,
} from "../services/agreementServices";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import AgmEquipmentCard from "./AgmEquipmentCard";
import { AuthContext } from "../AuthContext";
import AgreementInfo from "./AgreementInfo";
import EquipmentsTable from "./EquipmentsTable";

const AgmConfirmEquimentsDialog = (props) => {
  const { onClose, open, agreementId, formStatus = false, onEdit } = props;
  const { ctxStEquipments, setCtxStEquipments } = useContext(AuthContext);
  const [equipmentList, setEquipmentList] = useState([]);
  const [syncError, setSyncError] = useState("");
  const [loadingMsg, setLoadingMsg] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [equipmentsListSizeError, setEquipmentListSizeError] = useState("");
  useEffect(() => {
    setFormSubmitted(formStatus);
  }, [formStatus]);

  const getSyncEquipments = (agreementId) => {
    setEquipmentListSizeError("");
    setFormSubmitted(true);
    console.log("Sync Equipment Dialog", agreementId);
    setEquipmentList([]);
    setLoadingMsg("Loading Equipments. Please Wait..");
    getAgreementEquipmentsList(agreementId.id)
      .then(
        (res) => {
          const eqList = res.data.body;
          const statusCode = res.status;
          if (statusCode === 200) {
            console.log("Sync Equiplment Dialog equipment list :", eqList);
            if (eqList && eqList.length > 0) {
              if (eqList.length > 5) {
                setEquipmentListSizeError(
                  "Only 5 equipment records are allowed for the agreement. Please remove additional equipment to create an agreement."
                );
              } else {
                setEquipmentListSizeError("");
              }
              //setEquipmentList(eqList);
              setCtxStEquipments(eqList);
              setLoadingMsg("");
              setFormSubmitted(false);
            } else {
              setLoadingMsg("No equipments found");
              setFormSubmitted(false);
            }
          }
        },
        (err) => {
          console.log("unable to load equipment list", err);
          //setSaveError("unable to load equipment list");
          setFormSubmitted(false);
        }
      )
      .catch((err) => {
        console.log("Equipment List Internal Server Error", err);
        //setSaveError("Internal Server error");
        setFormSubmitted(false);
      });
  };

  useEffect(() => {
    if (agreementId) {
      getSyncEquipments(agreementId);
    }
    //setFormStatus(false);
  }, [agreementId]);

  const handleClose = (event, reason) => {
    if (reason === "escapeKeyDown" || reason === "backdropClick") {
      return;
    }
    //setSaveError("");
    onClose("close");
  };

  const handleEdit = () => {
    onEdit(agreementId);
  };

  const handleSave = () => {
    if (equipmentList.length > 5) {
      return;
    }
    setFormSubmitted(true);
    submitAgreementEquipments(agreementId.id)
      .then(
        (res) => {
          const statusCode = res.status;
          const data = res.data;
          console.log("sync result :", data);
          if (statusCode === 200) {
            onClose("success");
            setFormSubmitted(false);
          } else {
            setSyncError("Unable to Submit Equipments");
            setFormSubmitted(false);
          }
        },
        (err) => {
          console.log("Equipment Submit error :", err);
          setSyncError("Error :", err);
          setFormSubmitted(false);
        }
      )
      .catch((err) => {
        console.error("Equipment Submit internal server error :", err);
        setSyncError("Internal server error");
        setFormSubmitted(false);
      });
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        component: "div",
      }}
      fullWidth={true}
      maxWidth={"xl"}
      disableEscapeKeyDown
    >
      <DialogTitle>Confirm Equipment List</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2} padding={3}>
          <Grid item xs={12}>
            <AgreementInfo agreement={agreementId} />
          </Grid>
          {ctxStEquipments.length > 0 ? (
            <Grid item xs={12}>
              <EquipmentsTable
                selectedInvoice={agreementId}
                showAction={false}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography
                color="secondary"
                variant="caption"
                align="left"
                sx={{ fontSize: "14px" }}
              >
                {loadingMsg}
              </Typography>
            </Grid>
          )}
        </Grid>

        {syncError && (
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "10px" }}
          >
            {syncError}
          </Typography>
        )}
        {equipmentsListSizeError && (
          <Typography
            variant="body"
            sx={{ color: "#f44336", fontSize: "10px" }}
          >
            {equipmentsListSizeError}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={formSubmitted ? true : false}
          onClick={handleEdit}
          color="success"
        >
          Edit
        </Button>
        <Button
          disabled={formSubmitted ? true : false}
          onClick={handleClose}
          color="warning"
        >
          Cancel
        </Button>
        <Button
          disabled={
            formSubmitted ||
            equipmentsListSizeError ||
            !ctxStEquipments.length > 0
              ? true
              : false
          }
          onClick={handleSave}
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AgmConfirmEquimentsDialog;
